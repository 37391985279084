import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, CircularProgress } from "@mui/material";
import SideBar from "./components/SideBar";
import "../../styles/Course.css";
import { postData } from "../../services/Request";
import AI from "../../assets/Poster/AI.png";
import MS from "../../assets/Poster/MS.png";
import SD from "../../assets/Poster/SD.png";
import SC from "../../assets/Poster/Cyber-Security.jpeg";

const imageMapping = {
  "Microsoft Dynamics": MS,
  "Software Development": SD,
  "Artificial Intelligence": AI,
  "Cyber Security": SC,
};

const MyCourses = () => {
  const nav = useNavigate();
  const [userInfo, setUserInfo] = useState([]);
  const [userCourses, setUserCourses] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading status
  const [url, setUrl] = useState();

  useEffect(() => {
    const page = localStorage.getItem("pageUrl");
    setUrl(page);
    const user = localStorage.getItem("user");
    const data = JSON.parse(user);
    setUserInfo(data);
    postData("usercourses", {
      userId: data.userId,
    })
      .then((response) => {
        if (response.status === true) {
          setUserCourses(response.courses);
        }
      })
      .finally(() => setLoading(false)); // Set loading to false when API call completes
  }, []);

  const handleGoWhatsapp = () => {
    window.open("https://chat.whatsapp.com/L0362sTsqwiHrv23KrMscn", "_blank");
  };

  const handleClickButton = (course) => {
    const newPage = `${url}teachecall?courseName=${course}`;
    window.open(newPage, "_blank");
  };

  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.5)", // Semi-transparent white background
            backdropFilter: "blur(8px)", // Blurred background
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      )}
      <Grid container>
        <Grid lg={2} xs={12} md={3}>
          <SideBar />
        </Grid>
        <Grid xs={12} lg={9} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={12} className="heading">
              My Courses
            </Grid>
            <Grid item ml={3}>
              <div className="search-item">
                <div className="input-search">
                  <div style={{ position: "relative" }}>
                    <div>
                      <input
                        type="text"
                        className="search-input"
                        placeholder="search for a course"
                        id="text"
                      ></input>
                    </div>
                    <div>
                      <button className="search-button">Search</button>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            {userCourses.length > 0 ? (
              <Grid item xs={12} ml={3}>
                <Grid container spacing={3}>
                  {userCourses.map((product, index) => (
                    <Grid item xs={12} md={6} lg={4} key={index}>
                      <div className="img-box">
                        <img
                          src={imageMapping[product.courseName]}
                          className="sp-img"
                          style={{ borderRadius: "5px" }}
                        />
                      </div>
                      <div className="level-corses">
                        <span className="level">{product.courseLevel}</span>
                      </div>
                      <div className="sp-title">{product.courseName}</div>
                      <div className="sp-text">{product.description}</div>

                      {product.status === true ? (
                        <button
                          className="sp-button"
                          onClick={() => handleClickButton(product.courseName)}
                        >
                          Live class
                        </button>
                      ) : (
                        <button
                          className="sp-button"
                          onClick={() => handleGoWhatsapp()}
                        >
                          Enroll
                        </button>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ) : (
              <Grid item>
                <div className="my-text">
                  You have not enrolled for any course yet. Explore our courses
                  to enroll and start learning
                </div>
                <button className="my-button">Explore Courses</button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default MyCourses;
