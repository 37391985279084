import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import AI from "../assets/Poster/AI.png";
import MS from "../assets/Poster/MS.png";
import SD from "../assets/Poster/SD.png";
import CS from "../assets/Poster/Cyber-Security.jpeg";
import "../styles/Specialization.css";
import CircularProgress from "@mui/material/CircularProgress";

const courses = [
  {
    img: MS,
    level: "Beginner",
    courses: "4 Courses",
    title: "Microsoft Dynamics",
    text: "",
  },
  {
    img: SD,
    level: "Intermediate",
    courses: "4 Courses",
    title: "Software Development ",
    text: "",
  },
  {
    img: AI,
    level: "Advance",
    courses: "4 Courses",
    title: "Artificial Intelligence",
    text: "",
  },
  {
    img: CS,
    level: "Intermediate",
    courses: "4 Courses",
    title: "Cyber Security",
    text: "",
  },
];

const CourseOffer = () => {
  const nav = useNavigate();
  const [loader, setLoder] = useState();

  const handleClickButton = (title) => {
    setLoder(title);
    const user = localStorage.getItem("user");
    const data = JSON.parse(user);
    if (data == null || data.usertype == "") {
      nav("/usersignup");
    } else if (data.usertype === "Student") {
      nav("/courses");
    }
  };

  return (
    <>
      <Grid container justifyContent="center" mt={3}>
        <Grid item xs={11}>
          <Grid container spacing={3} className="sp-box">
            {courses.map((product, index) => (
              <Grid item xs={12} md={6} lg={3} key={index}>
                <div className="img-box">
                  <img src={product.img} className="sp-img" />
                </div>
                <div className="level-corses">
                  <span className="level">{product.level}</span>
                  <span className="courses">{product.courses}</span>
                </div>
                <div className="sp-title">{product.title}</div>
                <div className="sp-text">{product.text}</div>

                {loader !== product.title ? (
                  <button
                    className="sp-button"
                    onClick={() => handleClickButton(product.title)}
                  >
                    Enroll
                  </button>
                ) : (
                  <button className="sp-button">
                    <CircularProgress />
                  </button>
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CourseOffer;
