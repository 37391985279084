import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import AI from "../../../assets/Poster/AI.png";
import MS from "../../../assets/Poster/MS.png";
import SD from "../../../assets/Poster/SD.png";
import CS from "../../../assets/Poster/Cyber-Security.jpeg";
import "../../../styles/Specialization.css";
import CircularProgress from "@mui/material/CircularProgress";
import { sendEmail, postData } from "../../../services/Request";
import Swal from "sweetalert2";

const courses = [
  {
    img: MS,
    level: "Beginner",
    courses: "4 Courses",
    title: "Microsoft Dynamics",
    text: "",
  },
  {
    img: SD,
    level: "Intermediate",
    courses: "4 Courses",
    title: "Software Development",
    text: "",
  },
  {
    img: AI,
    level: "Advance",
    courses: "4 Courses",
    title: "Artificial Intelligence",
    text: "",
  },
  {
    img: CS,
    level: "Intermediate",
    courses: "4 Courses",
    title: "Cyber Security",
    text: "",
  },
];

const Specialization = () => {
  const nav = useNavigate();
  const [loader, setLoder] = useState();
  const [userInfo, setUserInfo] = useState([]);
  const [courseName, setCourseName] = useState();

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user === "" || user === null) {
      nav("/");
    } else {
      const data = JSON.parse(user);
      setUserInfo(data);
    }
  }, []);

  const mailStudent = {
    fullName: userInfo.firstname + " " + userInfo.lastname,
    email: userInfo.email,
    courseName: courseName,
  };

  const mailAdmin = {
    fullName: userInfo.firstname + " " + userInfo.lastname,
    email: "gayoola@wavelength-consultancy.com",
    courseName: courseName,
  };
  const handleClickButton = (title) => {
    setLoder(title);
    setCourseName(title);
    const user = localStorage.getItem("user");
    if (user === "" || user === null) {
      nav("/usersignup");
    } else {
      const applyCourse = {
        userId: userInfo.userId,
        courseName: title,
        courseLevel: "Beginner",
      };
      postData("selectcourse", applyCourse).then((response) => {
        if (response.status === true) {
          sendEmail("/enrollMail.php", mailStudent);
          sendEmail("/enrollMailAdmin.php", mailAdmin);
          Swal.fire({
            title: "Congratulations",
            text: "You Enroll in this Course",
            icon: "success",
          });
          setLoder("");
          window.open(
            "https://chat.whatsapp.com/L0362sTsqwiHrv23KrMscn",
            "_blank"
          );
        } else if (response.status === false) {
          Swal.fire({
            title: "Sorry",
            text: response.message,
            icon: "warning",
          });
          setLoder("");
        } else {
          Swal.fire({
            title: "Sorry",
            text: "You Cannot Enroll in this Course",
            icon: "error",
          });
          setLoder("");
        }
      });
    }
  };
  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={11}>
          <Grid container spacing={3} className="sp-box">
            <Grid item xs={12}>
              <div className="search-item">
                <div className="input-search">
                  <div style={{ position: "relative" }}>
                    <div>
                      <input
                        type="text"
                        className="search-input"
                        placeholder="search for a course"
                        id="text"
                      ></input>
                    </div>
                    <div>
                      <button className="search-button">Search</button>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            {courses.map((product, index) => (
              <Grid item xs={12} md={6} lg={4} key={index}>
                <div className="img-box">
                  <img src={product.img} className="sp-img" />
                </div>
                <div className="level-corses">
                  <span className="level">{product.level}</span>
                  <span className="courses">{product.courses}</span>
                </div>
                <div className="sp-title">{product.title}</div>
                <div className="sp-text">{product.text}</div>

                {loader !== product.title ? (
                  <button
                    className="sp-button"
                    onClick={() => handleClickButton(product.title)}
                  >
                    Enroll
                  </button>
                ) : (
                  <button className="sp-button">
                    <CircularProgress />
                  </button>
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Specialization;
