import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import { Grid, Alert } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { postData, sendEmail } from "../../services/Request";
import CircularProgress from "@mui/material/CircularProgress";
import S1 from "../../assets/ContactUs/S1.jpg";
import logo from "../../assets/Header/logo3.png";
import "../../styles/SignUp.css";

export default function UserSignUp() {
  const nav = useNavigate();

  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [loader, setLoder] = useState(false);
  const [agree, setAgree] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    var details = {
      firstname: e.target.firstname.value,
      lastname: e.target.lastname.value,
      email: e.target.email.value,
      password: e.target.password.value,
      confirm_password: e.target.confirm_password.value,
      phone: e.target.phone.value,
    };
    const mailStudent = {
      fullName: e.target.firstname.value + " " + e.target.lastname.value,
      email: e.target.email.value,
    };
    const mailAdmin = {
      fullName: e.target.firstname.value + " " + e.target.lastname.value,
      email: "gayoola@wavelength-consultancy.com",
    };
    if (details.firstname.trim() === "") {
      setAlertMessage("Input your first name");
      setSeverity("warning");
    } else if (details.lastname.trim() === "") {
      setAlertMessage("Input your last name");
      setSeverity("warning");
    } else if (details.email.trim() === "") {
      setAlertMessage("Input your email");
      setSeverity("warning");
    } else if (details.password.trim() !== details.confirm_password.trim()) {
      setAlertMessage("Passwords do not match");
      setSeverity("warning");
    } else {
      setLoder(true);
      setAlertMessage("");
      postData("usersignup", details)
        .then((response) => {
          setLoder(false);
          if (response.status === true) {
            sendEmail("/mail.php", mailStudent);
            sendEmail("/mailAdmin.php", mailAdmin);
            setAlertMessage(response.message);
            setSeverity("success");
            nav("/");
          } else {
            setAlertMessage(response.message);
            setSeverity("error");
          }
        })
        .catch((error) => {
          setLoder(false);
          if (error.request.status === 422) {
            setAlertMessage(error.response.data.errors.email);
            setSeverity("error");
          } else if (error.request.status === 500) {
            setAlertMessage(
              "Connection to internet lost, please check connection and retry"
            );
            setSeverity("error");
          } else {
            setAlertMessage("Error creating account");
            setSeverity("error");
          }
        });
    }
  };

  const handleAgreeChange = (event) => {
    setAgree(event.target.checked);
  };

  return (
    <div className="SignUp-container">
      <div style={{ padding: "25px" }}>
        <div className="img-mobile">
          <img src={logo}></img>
        </div>
        <div className="img-box">
          <img
            src={S1}
            className="signup-img"
            style={{ borderRadius: "22px" }}
          ></img>
        </div>
        <div className="form-box">
          <Container component="main" maxWidth="xs">
            {alertMessage && (
              <Alert variant="filled" severity={severity}>
                {alertMessage}
              </Alert>
            )}
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "700",
                  textAlign: "center",
                  paddingTop: "5px",
                }}
              >
                Sign Up
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "400", paddingTop: "5px" }}
              >
                Embark on your tech journey! Sign up for real-time sessions with
                expert tutors and start learning instantly.
              </Typography>

              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="firstname"
                  label="First Name"
                  name="firstname"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="lastname"
                  label="Last Name"
                  name="lastname"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="phone"
                  label="Phone Number"
                  name="phone"
                  autoComplete="phone"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="password"
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="confirm_password"
                  label="Confirm Password"
                  type="password"
                  id="confirm_password"
                  autoComplete="confirm_password"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agree}
                      onChange={handleAgreeChange}
                      color="primary"
                    />
                  }
                  label="I agree to the Terms & Conditions"
                />
                {loader === false && (
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={!agree}
                    sx={{
                      mt: 3,
                      mb: 2,
                      backgroundColor: "#006D34",
                      "&:hover": {
                        backgroundColor: "#45a049",
                      },
                    }}
                  >
                    Create an Account
                  </Button>
                )}
                {loader === true && (
                  <Button fullWidth variant="outlined">
                    <CircularProgress />
                  </Button>
                )}

                <Grid container justifyContent="center" paddingBottom={2}>
                  <Grid item>
                    Already have an account?
                    <Link
                      href="userlogin"
                      variant="body2"
                      style={{ color: "#006D34" }}
                    >
                      {" Login"}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </div>
      </div>
    </div>
  );
}
